import CribSetup from "pages/CribManagement/CribSetup";
import CribProfile from "pages/CribManagement/CribProfile/CribProfile";

export const cribNavigationCustomRoutes = [
  {
    path: `/:customRoute/setup/crib`,
    Component: CribSetup,
  },
];

export const cribCustomRoutes = [
  {
    path: `/:customRoute/crib/:crib_id`,
    Component: CribProfile,
  },
];
