import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useErrorContext } from "hooks/useErrorContext";
import crib from "api/crib";
import StepTracker from "../SetupTracker/step-tracker";
import ArrowBackward from "../../../../assets/svgs/ArrowBackward.jsx";
import ArrowForward from "../../../../assets/svgs/ArrowForward";
import BtnLoader from "components/Loader/btn-loader";
import storeSession from "utilities/session/sessionInitialIzer";
import "./style.css";

const SetupStep2 = ({
  currentStep,
  setCurrentStep,
  session,
  payload,
  userData,
}) => {
  const triggerAlert = useErrorContext();
  const navigate = useNavigate();
  const { customRoute } = useParams();

  const [summary, setSummary] = useState("");

  const cribSetupMutation = useMutation(
    (formData) => crib.setupUserCrib(formData),
    {
      onError: (error) => {
        triggerAlert({
          open: true,
          message:
            error?.response?.data?.error ||
            "There was an error, please try again later",
          type: "error",
        });
      },
      onSuccess: (response) => {
        if (response?.status === "success") {
          storeSession(response?.data?.session);
          customRoute
            ? navigate(
                session.role === "BCO"
                  ? `/${customRoute}/setup/neighborhoodprofile/step1`
                  : `/${customRoute}/crib/${session?.account_id}`
              )
            : navigate(
                session.role === "BCO"
                  ? `/setup/neighborhoodprofile/step1`
                  : `/crib/${session?.account_id}`
              );
        } else {
          triggerAlert({
            open: true,
            message: "Something went wrong, please try again later",
            type: "error",
          });
        }
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
    formData.append("summary", summary);
    cribSetupMutation.mutate(formData);
  };

  return (
    <div className="setup-two">
      <div className="setup-two__container">
        <div className="setup-two__header">
          <h2 className="setup-two__heading">Tell us about yourself</h2>
          <p className="setup-two__sub">Add your summary.</p>
        </div>
        <StepTracker currentStep={currentStep} />
        <form action="" className="setup-two__form" onSubmit={handleSubmit}>
          <div className="setup-two__form--control">
            <textarea
              name="summary"
              id="summary"
              cols="30"
              rows="6"
              className="setup-two__textarea"
              placeholder="Message"
              value={summary || userData?.crib_summary}
              onChange={(e) => setSummary(e.target.value)}
            ></textarea>
          </div>
          <button className="setup-two__cta--btn">
            {cribSetupMutation.isLoading ? (
              <BtnLoader
                border_bottom={"4px solid #FFFFFF"}
                border_right={"4px solid #FFFFFF"}
                border_left={"4px solid #838383"}
                border_top={"4px solid #838383"}
              />
            ) : (
              "Continue"
            )}
          </button>
        </form>
        <div className="setup-two__button--box">
          <button
            className="setup-two__btn"
            onClick={() => setCurrentStep("step1")}
          >
            <ArrowBackward />
            <span>Back</span>
          </button>
          <button
            className="setup-two__btn"
            onClick={() =>
              customRoute
                ? navigate(
                    session.role === "BCO"
                      ? `/${customRoute}/setup/neighborhoodprofile/step1`
                      : `/${customRoute}/crib/${session?.account_id}`
                  )
                : navigate(
                    session.role === "BCO"
                      ? `/setup/neighborhoodprofile/step1`
                      : `/crib/${session?.account_id}`
                  )
            }
          >
            <span>Skip</span>
            <ArrowForward />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetupStep2;
